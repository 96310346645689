const routes = [
  {
    path: "login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/view/authentication/SignIn.vue"
      ),
  },
  {
    path: "set-password",
    name: "set-password",
    component: () =>
      import(
        /* webpackChunkName: "comingSoon" */ "@/view/authentication/setPassword.vue"
      ),
  },
  {
    path: "register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/view/authentication/UserRegistration.vue"
      ),
  },

  {
    path: "chp-login",
    name: "chp-login",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/view/authentication/CHPLogin.vue"
      ),
  },
  {
    path: "/:a_part",
    redirect: (to) => {
      const a_code = to.query.a_code;
      return {
        name: "register",
        params: { a_part: to.params.
          _part },
        query: { a_code: a_code },
      };
    },
  },
  {
    path: "/activity-confirm/:a_part",
    name: "activity-register",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/view/authentication/ActivityConfirm.vue"
      ),
  },
  {
    path: "/auth/activity-confirm",
    name: "activity-confirm",
    component: () => import("@/view/authentication/ActivityConfirm.vue"),
  },
  {
    path: "/maintenance", 
    name: "maintenance",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/view/pages/403.vue"),
  },
];

export default routes;
